import { login, requireGuest } from '~/services/auth.server';
import styles from '~/styles/home.css?url';
import { Link, useFetcher } from '@remix-run/react';
import {
  Button,
  Checkbox,
  cn,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Radio,
  RadioGroup,
  useDisclosure,
} from "@heroui/react";
import { ArrowLeft, Lock, MailIcon } from 'lucide-react';
import { checkLoading } from '~/utils/helpers';
import { PaperAirplaneIcon } from '@heroicons/react/outline';
import PhoneInput from 'react-phone-input-2';
const PhoneInputComponent = PhoneInput.default ? PhoneInput.default : PhoneInput;

import phoneStyle from 'react-phone-input-2/lib/style.css?url';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logo from '~/components/home/Logo';
import { registerUser } from '~/services/api/homeapi.server';
export let loader = async ({ request }) => {
  await requireGuest({ request });

  return null;
};
export const CustomRadio = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      className={cn(
        'group inline-flex flex-row-reverse items-center justify-between tap-highlight-transparent hover:opacity-70 active:opacity-50',
        'mx-4 max-w-[300px] cursor-pointer gap-4 rounded-md border-2 p-4',
        'data-[selected=true]:border-primary',
      )}
    >
      {children}
    </Radio>
  );
};

export let meta = () => {
  return [{ title: 'Sign In — Lacasatour' }];
};
export function links() {
  return [
    { rel: 'stylesheet', href: styles },
    { rel: 'stylesheet', href: phoneStyle },
  ];
}

export default function Login() {
  const [errors, setErrors] = useState([]);

  const fetcher = useFetcher();
  useEffect(() => {
    let response = fetcher.data;
    if (response !== undefined) {
      setErrors(response);
    }
  }, [fetcher.data]);

  const handleLogin = (e) => {
    e.preventDefault();
    setErrors([]);
    let { email, password } = e.target.elements;

    fetcher.submit(
      {
        intent: 'login',
        email: email.value,
        password: password.value,
      },
      {
        method: 'POST',
      },
    );
  };

  const _renderLoginForm = () => {
    return (
      <form onSubmit={handleLogin}>
        <div className="w-full space-y-10">
          <div className="space-y-4">
            <div className="flex items-center justify-center">
              <Logo />
            </div>
            <p className="text-center text-3xl">Welcome</p>
            <Divider />
          </div>
          <Input
            type="email"
            label="Email"
            autoFocus
            name="email"
            placeholder="you@example.com"
            labelPlacement="outside"
            isRequired
            startContent={
              <MailIcon className="pointer-events-none flex-shrink-0 text-2xl text-default-400" />
            }
          />

          <div>
            <Input
              type="password"
              label="Password"
              name="password"
              placeholder="********"
              labelPlacement="outside"
              isRequired
              startContent={
                <Lock className="pointer-events-none flex-shrink-0 text-2xl text-default-400" />
              }
            />
            <p className="float-right mt-1 cursor-pointer text-blue-400 hover:underline">
              Forgot Password?
            </p>
          </div>
          {errors.length > 0 && (
            <div>
              {errors.map((error, index) => (
                <div key={index} className="rounded-md bg-red-100 p-4">
                  <p className="text-center text-red-500">{error}</p>
                </div>
              ))}
            </div>
          )}

          <Button
            type="submit"
            className="btn-site-primary w-full"
            radius="full"
            isLoading={checkLoading(fetcher)}
          >
            Login
          </Button>
          <RegisterUser />
        </div>
      </form>
    );
  };

  return (
    <div className="h-screen p-4">
      <Link to={'/'}>
        <div className="inline-flex justify-center items-center hover:bg-gray-200 space-x-2 rounded-md bg-gray-50 p-2">
          <ArrowLeft className="h-5 w-5" />
          <p>Home</p>
        </div>
      </Link>
      <div className="flex h-full w-full items-center justify-center">
        <div className="w-full max-w-96 rounded-md p-4 shadow">{_renderLoginForm()}</div>
      </div>
    </div>
  );
}
const RegisterUser = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [formData, setFormData] = useState({});
  const submitFetcher = useFetcher();

  useEffect(() => {
    let response = submitFetcher.data;

    console.log({ response });
    // if (response !== undefined) {
    //   displaynotification(response);
    // }
  }, [submitFetcher.data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    //validate phone with length equal to us number length

    // check if phone exist in formData and if it is not equal to 12
    if (!formData.phone) {
      toast.error('Invalid phone number');
    } else if (formData.phone.length != 11) {
      toast.error('Invalid phone number');
    } else if (formData.password.length < 8) {
      toast.error('Password must be at least 8 characters');
    } else if (formData.password != formData.password_confirmation) {
      toast.error('Password does not match');
    } else {
      console.log({ formData });
      submitFetcher.submit(
        {
          intent: 'register',
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          company_name: formData.company_name,
          phone: formData.phone,
          password: formData.password,
          password_confirmation: formData.password_confirmation,
          isRealtor: formData.isRealtor,
          // regUrl: regUrl,
        },
        {
          method: 'POST',
        },
      );
    }
  };

  return (
    <>
      <div>
        Not a Member?{' '}
        <span onClick={onOpen} className="cursor-pointer text-lime-500 hover:underline">
          Register here.
        </span>{' '}
      </div>
      <Modal
        isDismissable={true}
        hideCloseButton={true}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1">Register</ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="space-y-4">
                  <div className="flex justify-center">
                    <RadioGroup
                      onValueChange={(value) =>
                        setFormData((prevData) => {
                          return { ...prevData, isRealtor: value };
                        })
                      }
                      name="isRealtor"
                      isRequired
                      orientation="horizontal"
                      label="Are you a realtor?"
                    >
                      <CustomRadio value="YES">Yes</CustomRadio>
                      <CustomRadio value="No">No</CustomRadio>
                    </RadioGroup>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <Input
                      name="first_name"
                      type="text"
                      label="First Name"
                      placeholder="john"
                      isRequired
                      onChange={(e) =>
                        setFormData((prevData) => {
                          return { ...prevData, [e.target.name]: e.target.value };
                        })
                      }
                    />
                    <Input
                      name="last_name"
                      type="text"
                      label="Last Name"
                      placeholder="Doe"
                      autofill="none"
                      autoComplete="none"
                      aria-autocomplete="none"
                      isRequired
                      onChange={(e) =>
                        setFormData((prevData) => {
                          return { ...prevData, [e.target.name]: e.target.value };
                        })
                      }
                    />
                  </div>
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="john@doe.com"
                    autoComplete="new-user-email-jux"
                    aria-autocomplete="none"
                    startContent={<MailIcon className="h-5 w-5" />}
                    isRequired
                    onChange={(e) =>
                      setFormData((prevData) => {
                        return { ...prevData, [e.target.name]: e.target.value };
                      })
                    }
                  />
                  {formData.isRealtor == 'YES' && (
                    <Input
                      name="company_name"
                      type="text"
                      label="Company Name"
                      autofill="none"
                      placeholder="Compnay Name"
                      isRequired
                      onChange={(e) =>
                        setFormData((prevData) => {
                          return { ...prevData, [e.target.name]: e.target.value };
                        })
                      }
                    />
                  )}
                  <Input
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="********"
                    isRequired
                    aria-autocomplete="none"
                    startContent={<Lock className="h-5 w-5" />}
                    onChange={(e) =>
                      setFormData((prevData) => {
                        return { ...prevData, [e.target.name]: e.target.value };
                      })
                    }
                  />
                  <Input
                    name="password_confirmation"
                    type="password"
                    label="Confirm Password"
                    placeholder="********"
                    isRequired
                    startContent={<Lock className="h-5 w-5" />}
                    onChange={(e) =>
                      setFormData((prevData) => {
                        return { ...prevData, [e.target.name]: e.target.value };
                      })
                    }
                  />
                  <div>
                    <label htmlFor="phone">
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <PhoneInputComponent
                      country={'us'}
                      onlyCountries={['us']}
                      name="phone"
                      inputProps={{
                        name: 'phone',
                        required: true,
                        className: 'form-input',
                        style: { paddingLeft: '45px' },
                      }}
                      countryCodeEditable={false}
                      value={formData.phone}
                      onChange={(value, country, e) =>
                        setFormData((prevData) => {
                          return { ...prevData, [e.target.name]: value };
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mt-2 flex space-x-2">
                  <Checkbox>Agree to </Checkbox>{' '}
                  <p
                    className="cursor-pointer text-blue-400 underline hover:text-blue-600"
                    onClick={onOpen}
                  >
                    Terms & Conditions.
                  </p>
                </div>
                <div className="mt-4">
                  <Button
                    className="btn-site-primary w-full font-bold leading-5"
                    type="submit"
                    radius="full"
                    variant="shadow"
                    isLoading={submitFetcher.state == 'submitting'}
                  >
                    <PaperAirplaneIcon className="h-5 w-5 rotate-90" />
                    <span>Save</span>
                  </Button>
                </div>
              </form>
            </ModalBody>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export let action = async ({ request }) => {
  await requireGuest({ request });
  let formData = await request.formData();
  let intent = formData.get('intent');

  switch (intent) {
    case 'login': {
      let email = formData.get('email');
      let password = formData.get('password');

      let { errors, redirector } = await login({ request, email, password });

      return errors || redirector;
    }

    case 'register': {
      let isRealtor = formData.get('isRealtor');
      let userInfo = {
        first_name: formData.get('first_name'),
        last_name: formData.get('last_name'),
        email: formData.get('email'),
        company_name: isRealtor == 'YES' ? formData.get('company_name') : null,
        phone: formData.get('phone'),
        password: formData.get('password'),
        password_confirmation: formData.get('password_confirmation'),
        user_type: isRealtor == 'YES' ? 'agent' : 'homeowner',
        isRealtor: isRealtor,
      };
      let { errors, redirector } = await registerUser({
        request,
        userInfo,
        redirectUrl: '/dashboard',
      });

      return errors || redirector;
    }
    default:
      break;
  }
};
